<template>
	<div class="container">
		<div class="block50t050">
			<template v-for="block in components">
				<Map v-if="block.component == 'map'"></Map>
				<ImageSlider :data="block.data" v-if="block.component == 'imageSlider'"></ImageSlider>
				<Contacts :data="block.data" v-if="block.component == 'contacts'"></Contacts>
				<Advantages v-if="block.component == 'advantages'" :data="block.data" :columns="block.columns" :sliderInMobile="block.sliderInMobile"></Advantages>
			</template>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/Block50t050'
</style>

<script>
	import	ImageSlider from '@/components/app/ImageSlider'
	import	Map from '@/components/app/Map'
	import	Contacts from '@/components/app/Contacts'
	import 	Advantages from '@/components/app/Advantages'
	export default {
		props: ['components'],
		components: {
			ImageSlider,
			Map,
			Contacts,
			Advantages
		}
	}
</script>
